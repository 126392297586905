/*
Template Name: Ubold - Responsive Bootstrap 5 Admin Dashboard
Version: 7.0.0
Author: CoderThemes
Email: support@coderthemes.com
File: Main Css File
*/

// Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "./node_modules/bootstrap/scss/mixins";

// Structure
@import "../custom/structure/topbar";
@import "../custom/structure/right-sidebar";
@import "../custom/structure/page-head";
@import "../custom/structure/sidenav";
@import "../custom/structure/two-column-menu";
@import "../custom/structure/horizontal";
@import "../custom/structure/footer";

//Pages
@import "../custom/pages/authentication";
@import "../custom/pages/components-demo";
@import "../custom/pages/error";
@import "../custom/pages/logout";
@import "../custom/pages/faq";
@import "../custom/pages/maintenance";
@import "../custom/pages/timeline";
@import "../custom/pages/email";
@import "../custom/pages/sitemap";
@import "../custom/pages/search-results";
@import "../custom/pages/pricing";
@import "../custom/pages/gallery";
@import "../custom/pages/coming-soon";
@import "../custom/pages/profile";
@import "../custom/pages/task";
@import "../custom/pages/kanbanboard";
@import "../custom/pages/ecommerce";
@import "../custom/pages/order-detail";


// Plugins
@import "../custom/plugins/waves";
@import "../custom/plugins/apexcharts";
@import "../custom/plugins/ion-rangeslider";
@import "../custom/plugins/calendar";
@import "../custom/plugins/colorpicker";
@import "../custom/plugins/sparkline-chart";
@import "../custom/plugins/datatable";
@import "../custom/plugins/daterange";
@import "../custom/plugins/form-wizard";
@import "../custom/plugins/select2";
@import "../custom/plugins/slimscroll";
@import "../custom/plugins/toaster";
@import "../custom/plugins/sweetalert";
@import "../custom/plugins/selectize";
@import "../custom/plugins/tippy";
@import "../custom/plugins/nestable-list";
@import "../custom/plugins/hopscotch";
@import "../custom/plugins/flot";
@import "../custom/plugins/morris";
@import "../custom/plugins/chartjs";
@import "../custom/plugins/chartist";
@import "../custom/plugins/c3";
@import "../custom/plugins/ricksaw";
@import "../custom/plugins/responsive-table";
@import "../custom/plugins/footables";
@import "../custom/plugins/bootstrap-tables";
@import "../custom/plugins/tablesaw";
@import "../custom/plugins/jsgrid";
@import "../custom/plugins/multiple-select";
@import "../custom/plugins/autocomplete";
@import "../custom/plugins/bootstrap-touchspin";
@import "../custom/plugins/parsley";
@import "../custom/plugins/flatpickr";
@import "../custom/plugins/clockpicker";
@import "../custom/plugins/quilljs-editor";
@import "../custom/plugins/dropzone";
@import "../custom/plugins/dropify";
@import "../custom/plugins/x-editable";
@import "../custom/plugins/cropper";
@import "../custom/plugins/google-maps";
@import "../custom/plugins/vector-maps";
@import "../custom/plugins/mapeal-maps";
@import "../custom/plugins/datepicker";
//@import "../custom/plugins/dragula";

.dashed-border {
  border: none;
  border-top: 0.05px dashed #D1D1D1;
  margin: 20px 0;
  opacity: 0.7;
}
